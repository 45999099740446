// Global vars
var captureScreen       = false;
var captureMicrophone   = false;
var captureCamera       = false;
var userStream          = null;
var screenStream        = null;
var mergedStream        = null;
var mediaBlob           = null;
var cameraBlob          = null;
var mediaChunks         = null;
var cameraChunks        = null;
var mediaRecorder       = null;
var cameraRecorder      = null;
var screenFileName      = '';
var cameraFileName      = '';
var interval            = '';
var duration            = 0;
var sharePermission     = false;

async function startUXRecorder(recordScreen, recordMicrophone, recordCamera) {
    // Initialize array to allow file overwriting
    mediaChunks  = [];
    cameraChunks = [];

    // Designate which capture policies need to be enabled
    captureScreen     = recordScreen;
    captureMicrophone = recordMicrophone;
    captureCamera     = recordCamera;

    screenFileName = Date.now() + 'screen.mp4';
    cameraFileName = Date.now() + 'camera.mp4';

    try {
        /**
         * getDisplayMedia is used for recording the screen: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia
         * getUserMedia is used for both webcam and microphone: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
         */
        if (captureScreen) {
            screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    cursor: 'always',
                    displaySurface: 'browser',
                }
            });

            sharePermission = true
        }

        if (captureMicrophone || captureCamera) {
            userStream = await navigator.mediaDevices.getUserMedia({
                video: captureCamera,
                audio: captureMicrophone
            });
        }

        startRecording();

        return true;
    } catch(error) {
        return false;
    }
}

function startRecording() {
    if (captureScreen) {
        if (captureMicrophone) {
            // We merge the audiotrack from user media with the screen track to generate a single source
            mergedStream = new MediaStream([...userStream.getAudioTracks(), ...screenStream.getTracks()]);
            mediaRecorder = new MediaRecorder(mergedStream);
        } else {
            mediaRecorder = new MediaRecorder(screenStream);
        }

        mediaRecorder.ondataavailable = pushDataChunks;
        mediaRecorder.start();
    }

    if (captureCamera) {
        // If webcam is required, we'll generate a second source
        cameraRecorder = new MediaRecorder(userStream);

        cameraRecorder.ondataavailable = pushCameraChunks;
        cameraRecorder.start();
    }

    return true;
}

function stopUXRecorder() {

    clearInterval(interval);

    if (captureScreen) {
        screenStream.getTracks().forEach(track => track.stop());

        if (captureMicrophone) {
            userStream.getTracks().forEach(track => track.stop());
        }
        mediaRecorder.stop();
    }

    if (captureCamera) {
        userStream.getTracks().forEach(track => track.stop());
        cameraRecorder.stop();
    }
}

async function checkDeniedPermissions() {
    var permissionsDenied = []

    await navigator.mediaDevices.getUserMedia({ audio: true })
    .then(function(stream) {
    })
    .catch(function(err) {
        permissionsDenied.push('microphone')
    });

    await navigator.mediaDevices.getUserMedia({ video: true })
    .then(function(stream) {
    })
    .catch(function(err) {
        permissionsDenied.push('camera')
    });

    if(!sharePermission) permissionsDenied.push('screen')

    return permissionsDenied
  }

// Duplicate methods for each media record
function pushDataChunks(event) {
    mediaChunks.push(event.data);
    buildMediaBlob();
}

function pushCameraChunks(event) {
    cameraChunks.push(event.data);
    buildCameraBlob();
}

function buildMediaBlob() {
    mediaBlob = new Blob(mediaChunks, {
        type: "video/mp4"
    });
}

function buildCameraBlob() {
    cameraBlob = new Blob(cameraChunks, {
        type: "video/mp4"
    });
}

// Return the files that will be sent to uxVide
function getStoredRecordings() {
    if (captureScreen) {
        if (mediaBlob == null) {
            return false;
        }

        var screenFile = new File([mediaBlob], screenFileName, {type: "video/mp4", lastModified: Date.now()});
    }

    if (captureCamera) {
        if (cameraBlob == null) {
            return false;
        }

        var cameraFile = new File([cameraBlob], cameraFileName, {type: "video/mp4", lastModified: Date.now()});
    }

    var recordings = {
        screenFile: captureScreen ? screenFile : '',
        cameraFile: captureCamera ? cameraFile : '',
    };

    return recordings;
}

function resetBlobRecordings() {
    mediaBlob = null;
    cameraBlob = null;
}

export {startUXRecorder, stopUXRecorder, checkDeniedPermissions, getStoredRecordings, resetBlobRecordings};
